import React, { useEffect, useState } from 'react';
import Layout from '../layouts/Layout';

export const PocTemplate = () => {
  const [isError, setError] = useState(false)
  const [isLoggedUser, setloggedUser] = useState()

  useEffect(() => {
    const loggedUser = localStorage.getItem('tbid');
    if (!loggedUser) {
      const origin = window.location.origin;
      const pathName = 'sessions/users/new?type=tbidlogin';
      window.location = `${origin}/${pathName}`;
      console.log('Path', `${origin}${pathName}`)
    } else {
      setloggedUser(loggedUser)
    }
  }, []);

  const submitForm = () => {
    const fName = document.querySelector("#First_Name__c").value;
    const lName = document.querySelector("#Last_Name__c").value;
    const instituteName = document.querySelector("#Institute_Name__c").value;
    const instituteEmail = document.querySelector("#Institute_Email__c").value;
    const vCountry = document.querySelector("#Country__c").value;
    const vState = document.querySelector("#State__c").value;

    if (!fName || !lName || !instituteName || !instituteEmail || !vCountry || !vState) {
      setError(true);
      return false;
    } else {
      setError(false);
    }
    console.log('submitting....');
    document.querySelector('#form-result').classList.add('slds-hide');
    document.querySelector('#submit').disabled = true;
    document.querySelector('#submit').value = 'Submitting...';
    const formHandlerData = {
      handlerName: "academic_form_handler",
      TBID__c: isLoggedUser,
      First_Name__c: fName,
      Last_Name__c: lName,
      Institute_Name__c: instituteName,
      Institute_Email__c: instituteEmail,
      Country__c: vCountry,
      State__c: vState
    }

    fetch('https://drm--poonamtbc.sandbox.my.salesforce-sites.com/handler/services/apexrest/handler', {
      method: 'POST',
      mode: 'cors',
      referrerPolicy: 'origin-when-cross-origin',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': 'origin, X-Custom-Header',
        'Access-Control-Allow-Method': 'POST',
        'Access-Control-Allow-Origin': '*'
      },
      body: JSON.stringify(formHandlerData)

    }).then(res => res.json()).then(res => {
      console.log(res);
      document.querySelector('#submit').disabled = false;
      document.querySelector('#submit').value = 'Submit';
      document.querySelector('#form-result-msg').innerHTML = res.status;
      document.querySelector('#form-result').classList.remove('slds-hide');
      document.querySelector('#form-result').classList.remove('slds-hidden');
      document.querySelector('#form-result').classList.add('slds-show');
    });

  }
  console.log('Logged User', isLoggedUser)

  return (
    <Layout title='POC Form Handler' lang='en' slug='poc'>
      {isLoggedUser && (
        <tm-page class="theme--salesforce">
          <div style={{ textAlign: 'center', fontSize: '20px', color: '#ff0000' }}>{isError && 'Please provide all the information'}</div>

          <tm-section-a title="Form Handler POC">
            <div class="poc-form slds-grid slds-wrap slds-gutters slds-align_absolute-center ">
              <form accept-charset="UTF-8" class="form" id="pardot-form" method="post" >
                <input id="handlerName" name="handlerName" type="hidden" value="academic_form_handler" />
                <div class="slds-col slds-grid slds-wrap slds-m-bottom_x-small">
                  <div class="slds-col slds-size_1-of-2">
                    <label for="First_Name__c">First Name</label>
                  </div>
                  <div class="slds-col slds-size_1-of-2">
                    <input type="text" name="First_Name__c" id="First_Name__c"></input>
                  </div>
                </div>

                <div class="slds-col  slds-grid slds-wrap slds-m-bottom_x-small">
                  <div class="slds-col slds-size_1-of-2">
                    <label for="Last_Name__c">Last Name</label>
                  </div>
                  <div class="slds-col slds-size_1-of-2">
                    <input type="text" name="Last_Name__c" id="Last_Name__c"></input>
                  </div>
                </div>

                <div class="slds-col  slds-grid slds-wrap slds-m-bottom_x-small">
                  <div class="slds-col slds-size_1-of-2">
                    <label for="Institute_Name__c">Institute Name</label>
                  </div>
                  <div class="slds-col slds-size_1-of-2">
                    <input type="text" name="Institute_Name__c" id="Institute_Name__c"></input>
                  </div>
                </div>

                <div class="slds-col  slds-grid slds-wrap slds-m-bottom_x-small">
                  <div class="slds-col slds-size_1-of-2">
                    <label for="Institute_Email__c">Institute Email</label>
                  </div>
                  <div class="slds-col slds-size_1-of-2">
                    <input type="text" name="Institute_Email__c" id="Institute_Email__c"></input>
                  </div>
                </div>

                <div class="slds-col slds-grid slds-wrap slds-m-bottom_x-small">
                  <div class="slds-col slds-size_1-of-2">
                    <label for="Country__c">Country</label>
                  </div>
                  <div class="slds-col slds-size_1-of-2">
                    <input type="text" name="Country__c" id="Country__c"></input>
                  </div>
                </div>

                <div class="slds-col  slds-grid slds-wrap slds-m-bottom_x-small">
                  <div class="slds-col slds-size_1-of-2">
                    <label for="State__c">State</label>
                  </div>
                  <div class="slds-col slds-size_1-of-2">
                    <input type="text" name="State__c" id="State__c"></input>
                  </div>
                </div>

                <div class="slds-col  slds-grid slds-wrap slds-m-bottom_x-small">
                  <div class="slds-col slds-size_1-of-1">
                    <input class="slds-button slds-button_brand" id="submit" type="button" value="Submit" onClick={submitForm} />
                  </div>
                </div>
                <div class="slds-col slds-size_1-of-1 slds-hidden" role="alert" id="form-result">
                  <tds-alert dismissible="true" id="form-result-msg"><span> result </span></tds-alert>
                </div>

              </form>
            </div>
          </tm-section-a>
        </tm-page>
      )}
    </Layout>
  );
};

export default PocTemplate;
